/*eslint no-unused-vars: 0*/

import { init as initApm } from "@elastic/apm-rum";
import React from "react";
import ReactDOM from "react-dom";
import FirstLoading from "./components/FirstLoading/FirstLoading";
import "./index.css";
import * as serviceWorker from "./serviceWorker";

if (
  process.env.REACT_APP_ELASTICAPM_URL &&
  !Boolean(localStorage.getItem("disableApm"))
) {
  const apm = initApm({
    serverUrl: process.env.REACT_APP_ELASTICAPM_URL,
    serviceName: process.env.REACT_APP_ELASTICAPM_SERVICE_NAME || "cvboard",
    transactionSampleRate: process.env.REACT_APP_ELASTICAPM_SAMPLING_RATE
      ? parseFloat(process.env.REACT_APP_ELASTICAPM_SAMPLING_RATE)
      : 1.0
  });
  apm.observe("transaction:start", transaction => {
    transaction.addLabels({
      cluster: process.env.REACT_APP_ELASTICAPM_CLUSTER_NAME,
      hospital: localStorage.getItem("hospitalId")
    });
  });
}

const Root = React.lazy(() => import("./Root.js"));

ReactDOM.render(
  <React.Suspense fallback={<FirstLoading msg="접속 중" />}>
    <Root />
  </React.Suspense>,
  document.getElementById("root")
);

serviceWorker.register();
