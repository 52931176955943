import { Box, Card, CardContent, Grid, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    backgroundImage: `url(bg/login2.png)`
  },
  container: {
    height: "100%"
  },
  cardRoot: {
    width: "500px",
    height: "302px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  content1: {
    fontSize: 33,
    fontWeight: "bold",
    color: "#00897b"
  },
  content2: {
    fontSize: 20,
    fontWeight: "bold",
    color: "rgba(0, 0, 0, 0.54)"
  }
}));

const FirstLoading = props => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <Grid
        container
        className={classes.container}
        direction={"column"}
        justifyContent={"flex-start"}
        alignContent={"center"}
      >
        <Grid item style={{ flex: 5 }} />
        <Grid container justifyContent={"center"}>
          <Grid item>
            <img
              src={"/clever.png"}
              style={{
                width: "261px",
                height: "115px",
                transform: "translateZ(0)"
              }}
              alt={"clever"}
            />
          </Grid>
        </Grid>
        <Grid item style={{ flex: 2 }} />
        <Grid item>
          <Grid container justifyContent={"center"}>
            <Grid item>
              <Card className={classes.cardRoot}>
                <CardContent>
                  <Box style={{ padding: 8 }}>
                    <p className={classes.content1}>Loading..</p>
                    <p className={classes.content2}>
                      Freshening up Clever with a contents download.
                    </p>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ flex: 7 }} />
      </Grid>
    </Box>
  );
};

FirstLoading.propTypes = {
  type: PropTypes.oneOf(["permission", "loading", undefined])
};

export default FirstLoading;
